:root {
  --color: #ffffff;
  --gradient-start: #8a2be2;
  --gradient-end: #8a2be2;
  --background: #15181f;
  --primary: #8a2be2;
  --primary-transparent: #892be23c;
  --dark-gray: #1f2229;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  font-family: Avenir Next Cyr;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Bold */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 700;
  font-style: normal;
  src: url("./assets/fonts/AvenirNextCyr-Bold.eot");
  src: url("./assets/fonts/AvenirNextCyr-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-Bold.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-Bold.ttf") format("truetype");
}

/* BoldItalic */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 700;
  font-style: italic;
  src: url("./assets/fonts/AvenirNextCyr-BoldItalic.eot");
  src: url("./assets/fonts/AvenirNextCyr-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-BoldItalic.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-BoldItalic.ttf") format("truetype");
}

/* Demi */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 600;
  font-style: normal;
  src: url("./assets/fonts/AvenirNextCyr-Demi.eot");
  src: url("./assets/fonts/AvenirNextCyr-Demi.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-Demi.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-Demi.ttf") format("truetype");
}

/* DemiItalic */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 600;
  font-style: italic;
  src: url("./assets/fonts/AvenirNextCyr-DemiItalic.eot");
  src: url("./assets/fonts/AvenirNextCyr-DemiItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-DemiItalic.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-DemiItalic.ttf") format("truetype");
}

/* Heavy */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 900;
  font-style: normal;
  src: url("./assets/fonts/AvenirNextCyr-Heavy.eot");
  src: url("./assets/fonts/AvenirNextCyr-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-Heavy.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-Heavy.ttf") format("truetype");
}

/* HeavyItalic */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 900;
  font-style: italic;
  src: url("./assets/fonts/AvenirNextCyr-HeavyItalic.eot");
  src: url("./assets/fonts/AvenirNextCyr-HeavyItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-HeavyItalic.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-HeavyItalic.ttf") format("truetype");
}

/* Light */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 300;
  font-style: normal;
  src: url("./assets/fonts/AvenirNextCyr-Light.eot");
  src: url("./assets/fonts/AvenirNextCyr-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-Light.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-Light.ttf") format("truetype");
}

/* LightItalic */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 300;
  font-style: italic;
  src: url("./assets/fonts/AvenirNextCyr-LightItalic.eot");
  src: url("./assets/fonts/AvenirNextCyr-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-LightItalic.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-LightItalic.ttf") format("truetype");
}

/* Medium */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 500;
  font-style: normal;
  src: url("./assets/fonts/AvenirNextCyr-Medium.eot");
  src: url("./assets/fonts/AvenirNextCyr-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-Medium.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-Medium.ttf") format("truetype");
}

/* MediumItalic */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 500;
  font-style: italic;
  src: url("./assets/fonts/AvenirNextCyr-MediumItalic.eot");
  src: url("./assets/fonts/AvenirNextCyr-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-MediumItalic.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-MediumItalic.ttf") format("truetype");
}

/* Normal */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 400;
  font-style: normal;
  src: url("./assets/fonts/AvenirNextCyr-Normal.eot");
  src: url("./assets/fonts/AvenirNextCyr-Normal.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-Normal.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-Normal.ttf") format("truetype");
}

/* NormalItalic */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 400;
  font-style: italic;
  src: url("./assets/fonts/AvenirNextCyr-NormalItalic.eot");
  src: url("./assets/fonts/AvenirNextCyr-NormalItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-NormalItalic.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-NormalItalic.ttf") format("truetype");
}

/* Thin */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 100;
  font-style: normal;
  src: url("./assets/fonts/AvenirNextCyr-Thin.eot");
  src: url("./assets/fonts/AvenirNextCyr-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-Thin.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-Thin.ttf") format("truetype");
}

/* ThinItalic */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 100;
  font-style: italic;
  src: url("./assets/fonts/AvenirNextCyr-ThinItalic.eot");
  src: url("./assets/fonts/AvenirNextCyr-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-ThinItalic.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-ThinItalic.ttf") format("truetype");
}

/* UltraLight */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 200;
  font-style: normal;
  src: url("./assets/fonts/AvenirNextCyr-UltraLight.eot");
  src: url("./assets/fonts/AvenirNextCyr-UltraLight.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-UltraLight.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-UltraLight.ttf") format("truetype");
}

/* UltraLightItalic */
@font-face {
  font-family: "Avenir Next Cyr";
  font-weight: 200;
  font-style: italic;
  src: url("./assets/fonts/AvenirNextCyr-UltraLightItalic.eot");
  src: url("./assets/fonts/AvenirNextCyr-UltraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirNextCyr-UltraLightItalic.woff") format("woff"),
    url("./assets/fonts/AvenirNextCyr-UltraLightItalic.ttf") format("truetype");
}
