.industriesWeServe {
  width: 100%;
  padding-top: 24px;
  margin-bottom: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.industriesWeServeTitle {
  font-size: 40px;
  margin: 0 24px 24px 24px;
  font-weight: 600;
  line-height: 100%;
  text-align: center;
}

.industriesWeServeText {
  font-size: 22px;
  max-width: 740px;
  text-align: center;
  margin: 0 24px 48px 24px;
}

.carousel {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.carousel__wrapper {
  display: flex;
  align-items: center;
  /* width: 100%; */
  overflow: hidden;
  margin: 0 auto;
}

.carousel__slide {
  animation: scroll 15s linear infinite;
  display: flex;
  align-items: center;

  flex: 0 0 auto;
  box-sizing: border-box;
}

.carousel__image {
  position: relative;
  height: 32px;
  width: 32px;
  margin-right: 8px;
  margin-left: 44px;
}

.carousel__text {
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  text-align: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-990.96px);
  }
}

@media (max-width: 768px) {
  .industriesWeServe {
    margin-bottom: 120px;
  }

  .industriesWeServeTitle {
    font-size: 28px;
  }

  .industriesWeServeText {
    font-size: 16px;
  }
}
