.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--background);
  border-bottom: 1px solid var(--color);
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  min-height: 72px;
}

.logo {
  width: 204.5px;
  height: 32px;
  cursor: pointer;
}

.animatedButton {
  animation: fadeIn 1.5s;
}

@keyframes fadeIn {
  0% {
    transform: scale(1);
  }
  12.5% {
    transform: scale(1.16);
  }
  25% {
    transform: scale(1);
  }
  37.5% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  62.5% {
    transform: scale(1.06);
  }
  75% {
    transform: scale(1);
  }
  87.5% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .logo {
    width: 153.6px;
    height: 24px;
  }
}
