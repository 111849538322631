.howItWorks {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 100%;
}

.stepsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
}

.howItWorksTitle {
  font-size: 68px;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  margin-bottom: 24px;
}

.underline {
  margin-bottom: 48px;
}

.stepContainer {
  display: flex;
  flex-direction: column;
  border: 2px solid #484d54;
  border-radius: 14px;
  width: 100%;
  gap: 8px;
}

.imageContainer {
  position: relative;
  background-color: var(--primary-transparent);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 280px;
}

.index {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary);
}

.image {
  position: relative;
  width: 100%;
  height: 100%;
}

.stepContent {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
}

.stepTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
}

.stepDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
}

@media (max-width: 768px) {
  .stepsContainer {
    flex-direction: column;
  }
  .howItWorksTitle {
    font-size: 48px;
    margin-bottom: 12px;
  }

  .underline {
    scale: 0.7;
    margin-bottom: 32px;
  }
}
