.didntReinvent {
  position: relative;
  padding: 24px;
  margin-top: 80px;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.didntReinventText {
  max-width: 936px;
  font-size: 40px;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  z-index: 1;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.violetText {
  color: #2b0052;
}

@media (max-width: 768px) {
  .didntReinventText {
    font-size: 28px;
  }

  .background {
    display: none;
  }

  .violetText {
    color: var(--primary);
  }
}
