.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--color);
  height: 40px;
  padding: 12px 24px;
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  font-family: inherit;
  transition: transform 0.2s;
}

.text {
  color: var(--background);
  font-size: 16px;
  font-weight: 400;
}

.gradient {
  background-image: linear-gradient(
    to right,
    var(--gradient-start),
    var(--gradient-end)
  );
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media not all and (hover: none) {
  .container:hover {
    transform: scale(1.1);
  }
  .disabled:hover {
    transform: none;
  }
}
