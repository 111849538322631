.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 180px;
  padding-top: 56px;
  padding-bottom: 80px;
  background-color: var(--color);
  overflow: hidden;
}

.footerEffect {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: -10%;
  z-index: 1;
  object-fit: contain;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 1200px;
  width: 100%;
  z-index: 1;
}

.topContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 80px;
}

.companyContainer {
  flex: 5;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.imageContainer {
  position: relative;
  width: 475px;
  height: 74.33px;
}

.companyDescription {
  max-width: 503px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: var(--background);
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid var(--background);
  padding-top: 32px;
}

.rightsReserved {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: var(--background);
}

.bold {
  font-weight: 600;
}

.socialMediaContainer {
  display: flex;
  gap: 12px;
}

.socialMediaIcon {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

@media (max-width: 860px) {
  .footerEffect {
    display: none;
  }
}

@media (max-width: 768px) {
  .footer {
    margin-top: 80px;
  }

  .topContainer {
    flex-direction: column;
    margin-bottom: 48px;
  }

  .buttonContainer {
    align-items: flex-start;
  }

  .imageContainer {
    width: 240px;
    height: 37.56px;
  }

  .bottomContainer {
    flex-direction: column;
    gap: 24px;
  }

  .socialMediaContainer {
    gap: 8px;
  }
}
