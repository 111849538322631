.intro {
  width: 100%;
  height: calc(100vh - 80px);
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: row;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  flex: 4;
  padding: 80px 24px;
  margin-bottom: 48px;
}

.introTitle {
  font-size: 72px;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 24px;
}

.introSubtitle {
  max-width: 500px;
  font-size: 22px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 32px;
}

.button {
  height: 48px;
  width: 166px;
  border-radius: 24px;
}

.buttonsContainer {
  display: flex;
  gap: 24px;
}

.storeButton {
  border-radius: 4px;
  width: auto;
  height: 48px;
}

.gradientText {
  background-image: linear-gradient(
    to right,
    var(--gradient-start),
    var(--gradient-end)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.orangeText {
  color: var(--primary);
}

.rightContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 4;
  height: 100%;
}

.phoneBackground {
  position: absolute;
  width: 100%;
  height: auto;
  top: auto;
  right: 0;
  object-fit: contain;
}

.image {
  position: relative;
  width: 282px;
  height: 608px;
  border-radius: 28px;
  overflow: hidden;
  z-index: 1;
}

@media (max-width: 768px) {
  .intro {
    height: auto;
    flex-direction: column;
  }

  .introTitle {
    font-size: 44px;
  }

  .introSubtitle {
    font-size: 20px;
  }

  .rightContainer {
    padding: 0px 24px 120px 24px;
  }

  .image {
    width: 180px;
    height: 388.085px;
  }
}
