.contactUs {
  width: 100%;
  padding: 24px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactUsTitle {
  font-size: 68px;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 80px;
  text-align: center;
  margin-bottom: 24px;
}

.underline {
  margin-bottom: 48px;
}

.form {
  width: 100%;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textInput {
  width: 100%;
  height: 48px;
  border: 1px solid var(--color);
  background-color: transparent;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 24px;
  font-family: inherit;
  color: var(--color);
}

.textArea {
  width: 100%;
  border: 1px solid var(--color);
  background-color: transparent;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 24px;
  font-family: inherit;
  color: var(--color);
}

.label {
  font-size: 16px;
  line-height: 150%;
  width: 100%;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .contactUsTitle {
    font-size: 48px;
    margin-bottom: 12px;
  }

  .underline {
    scale: 0.7;
    margin-bottom: 32px;
  }
}
